<template>
	<div>
		<TableList :columns="columns" :get_table_list="this.get_table_list" :form_data_seo="form_data_seo"
			:slot_table_list="['operation','status','address','check_date']" :submit_preprocessing="submit_preprocessing" ref="list" :rowKey="'order_id'">
			<template slot="left_btn">
				<div class="left_btn_box">
					<div class="recharge_all">
						<p>订单总数<span class="all_money">{{dataInfo.total_num}}</span></p>
						<p>订单总额<span class="all_money">{{dataInfo.total_price}}</span>元</p>
					</div>
				</div>
			</template>
			<template slot="right_btn">
				<a @click="report()">
					<a-button  class="a_btn">导出</a-button>
				</a>
			</template>

			<template slot="check_date" slot-scope="data">
				<span>
					{{data.record.check_in_date + '/' + data.record.check_out_date}}
				</span>
			</template>
			<template slot="operation" slot-scope="data">
				<span>
					<a @click="see(data.record)">查看</a>
				</span>
			</template>
			<template slot="address" slot-scope="data">
				<span >
					{{data.record.address?data.record.address:'--'}}
				</span>
			</template>
			
			<template slot="status" slot-scope="data">
				<span v-if="data.record.status==1" class="green_color">
					启用
				</span>
				<span v-else class="red_color">
				禁用
				</span>
			</template>
		</TableList>
	
	</div>
</template>

<script>
	import EditPop from "@/components/EditPop";
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";
	import SelectUser from "@/components/SelectUser";

	import {
		getHotelOrder,orderCensus,getOrderStatus
	} from "@/api/evection";
	import {
		getUserList
	} from "@/api/personnel";

	const columns = [{
			title: "订单编号",
			dataIndex: "order_id"
		},
		{
			title: "订单状态",
			dataIndex: "status_name"
		},
		{
			title: "酒店名称",
			dataIndex: "hotel_name",
		},
		{
			title: "酒店地址",
			dataIndex: "address",
			scopedSlots: {
				customRender: "address"
			}
		},
		{
			title: "住宿人数",
			dataIndex: "person_num"
		},
		{
			title: "房间数",
			dataIndex: "room_num"
		},
		{
			title: "订单总额",
			dataIndex: "order_price"
		},
		{
			title: "入离店时间",
			dataIndex: "check_date",
			width:200,
			scopedSlots: {
				customRender:'check_date'
			}
		},
		{
			title: "下单日期",
			dataIndex: "create_time",
			width:200,
		},
		{
			title: "操作",
			dataIndex: "operation",
			scopedSlots: {
				customRender: "operation"
			}
		}
	];



	export default {
		name: "Index",
		components: {
			EditPop,
			Form,
			TableList,
			SelectUser
		},
		data() {
			return {
				config: this.$config,
				get_table_list: getHotelOrder,
				submit_preprocessing: {
					array_to_string: ['department_id', 'group_id']
				},
				columns,
				selectedRowKeys: [],
				loading: false,
				visible: false,
				form_data_seo: {
					list: [

						{
							type: "text",
							name: "order_id",
							title: "订单编号",
							placeholder: "订单编号",
							options: {}
						},
						{
							type: "text",
							name: "hotel_name",
							title: "酒店名称",
							placeholder: "酒店名称",
							options: {}
						},
						{
							type: "text",
							name: "person_name",
							title: "入住人",
							placeholder: "入住人",
							options: {}
						},
						
						{
							type: 'select',
							title: '订单状态',
							name: 'status',
							options: {},
							list: [
							]
						},
						{
							type: "range_date",
							name: "range_date",
							title: "入住时间",
							options: {},
							start: {
								name: 'start_in_time'
							},
							end: {
								name: 'end_in_time'
							},
						},
						{
							type: "range_date",
							name: "range_date1",
							title: "离店时间",
							options: {},
							start: {
								name: 'start_out_time'
							},
							end: {
								name: 'end_out_time'
							},
						},
						{
						    type: "tree-select",
						    name: "department_id",
						    title: "部门",
						    options: {},
						    treeData: [],
						    multiple: true
						},

					],
					...this.$config.form_data_seo
				},
				dataInfo:{}
			};
		},


		async created() {
			// 缓存该页面
			this.$keep_route.add(this.$options.name);
			
			this.$method.get_department().then(res => {
			    this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department_id', 'treeData', res)
			});
			this.getorderCensus();
			this.get_OrderStatus()
		},
		methods: {
			get_OrderStatus(){
				getOrderStatus().then(res=>{
					 res.data.list =  res.data.list.map(item => {
						item.key = item.value;
						item.value = item.label;
						return item;
					});
					this.form_data_seo.list.forEach(item => {
						if (item.name == "status") {
							item.list = res.data.list;
						}
					});
				})
			},
			getorderCensus(){
				orderCensus().then(res=>{
					this.dataInfo =  res.data.data
				})
			},
			see(key) {
				this.$router.push("/evection/order_details?order_id=" + key.order_id);
			},
			report() {
				this.$router.push({
					path:"/report/modify_report",
					query:{
						report_key:"custom_trip_order",
						...this.$refs.list.seo_data
					}
				});
			},
		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");

	.left_btn_box {
		flex: inherit;

		p {
			margin: 5px 0px;
		}
	}
	.edit_pop{
		.ant-col-19 {
			width: 77%;
		}
		.ant-form-item-label {
			width: 22%;
		}
	}
	.red_color {
		color: red;
	}
	.green_color{
		color: #00CC10;
	}
	
	.recharge_all {
		font-size: 16px;
		display: flex;
		color: #333;
	
		.all_money {
			font-size: 20px;
			color: #FF0000;
			margin-left: 10px;
			margin-right: 30px;
			display: inline-block;
		}
	
	}
</style>
